import React, { useState } from "react"
import axios from "axios"
import { css } from "@emotion/react"
import Section from "~components/global/section"
import Input from "~components/global/input"
import Button from "~components/global/button"
import BlockContent from '@sanity/block-content-to-react'

const richTextSerializers = {
    types: {
      richTextButton: ({ node }) => (
        <>
        <a href={node.href} className="pill pill--solid-orange" target={node.target ? '_blank' : '_self'}>{node.text} <span className="pill__arrow">&rarr;</span></a><br/>
        </>
      )
    }
  }

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const ContactForm = ({ text, formName }) => {
    const [submitted, setSubmitted] = useState(false)
    const [values, setValues] = useState({})
    const [netlifyError, setNetlifyError] = useState(false)

    const submit = e => {
      e.preventDefault()
      const data = encode({"form-name": formName, ...values})
      if(window.location.hostname === "localhost"){
        setSubmitted(true)
      } else {
        axios.post(window.location.pathname, data, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }).then(res => {
          setSubmitted(true)
        })
        .catch(err =>
          setNetlifyError(true)
        )
      }
    }

    return(
    <Section name={`contact-form`} css={styles}>
        <div className="contact-form__text">
                <BlockContent blocks={text} serializers={richTextSerializers} />
            </div>
        <div className="contact-form__form">
        <form css={css`opacity: ${submitted ? 0 : 1}; `} method="POST" data-netlify="true" name={formName} onSubmit={e => submit(e)}>
            <input type="hidden" name="form-name" value={formName} />
            <Input name="name" label="Name" placeholder="Enter your name" onChange={e => setValues({...values, name: e.target.value})} />
            <Input type="email" name="email" label="Email" placeholder="Enter your email" onChange={e => setValues({...values, email: e.target.value})} required />
            <textarea name="message" id="message" placeholder="Enter your message" onChange={e => setValues({...values, message: e.target.value})}/>
            <div className="buttonWrapper">
            <Button onClick={() => null}>
                send
            </Button>
            </div>
          </form>
          <div className="contact-form__success" css={css`opacity: ${submitted ? 1 : 0}; `}>
            <p>Thanks. We'll be in touch.</p>
          </div>
          {netlifyError &&
            <div css={css`margin-top: 1em;`}>
              <p className="xs-sans">Your message could not be sent. Please try again later.</p>
            </div>
          }
        </div>
    </Section>
    )
}

const styles = css`
    margin: 3rem 0;
    .contact-form{
        &__text{
            grid-column: span 6;
            @media(max-width: 768px){
                margin-bottom: 3rem;
            }
        }
        &__form{
          grid-column: span 6;
          background: #FFF1E2;
          max-width: 600px;
          width: 100%;
          padding: 40px;
          border-radius: 10px;
          position: relative;
          /* margin: 0 auto; */
          box-sizing: border-box;
          color: #000000;
          @media(max-width: 768px){
              padding: 30px;
          }
          .buttonWrapper{
              /* display: flex; 
              justify-content: center; */
          }
          button{
              margin: 0 auto;
          }
        }
        &__success{
          position: absolute;
          top: 40px;
          left: 40px;
          pointer-events: none;
        }
    }
`

export default ContactForm