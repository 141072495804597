import React from 'react'
import Section from '~components/global/section'
import Newsletter from '~components/global/newsletter'
import InternalButton from '~components/global/internalButton'

const Cta = ({ el }) => {
  if(!el) return null
  switch (el._type) {
    case 'newsletterSignUp':
      return <Newsletter successMessage={el.successMessage} buttonText={el.buttonText}/>
    case 'internalButton':
      return <InternalButton data={el} />
    default:
      return null
  }
}

const TextIllo = ({ children, cta, bg, textColor, image }) => {
  return(
    <Section name={`text-illo text-illo--${bg}`}>
      <div className="text-illo__text">
        {children}
      </div>
      <div className="text-illo__image">
        <div>
          {image}
        </div>
      </div>
      <div className="text-illo__cta">
        <Cta el={cta} />
      </div>
    </Section>
  )
}

export default TextIllo
