import React, { useState } from 'react'

const Input = ({ name, label, placeholder, onChange, type, required }) => {
  const [focused, setFocused] = useState(false)
  const handleChange = e => {
    if(onChange){
      onChange(e)
    }
    return null
  }
  return (
    <div className={focused ? 'input input--focused' : 'input'}>
      <label htmlFor={name}>{label}</label>
      <input type={type ?? 'text'} id={name} name={name} required={required ? 'true' : ''} placeholder={placeholder} onChange={e => handleChange(e)} onFocus={() => setFocused(true)} onBlur={() => setFocused(false)}/>
    </div>
  )
}

export default Input
