import React from 'react'
import Section from '~components/global/section'
import Video from '~components/video'
import ContactForm from "~components/global/contactForm"

const TextImage = ({ children, textAlign, flip, imageFit, imageStyle, img, textBg, videoSrc, contactForm }) => {
  return (
    <Section name={`text-image ${flip ? 'text-image--flip' : ''} ${imageFit === "contain" ? 'text-image--contain' : ''} text-image--${textAlign} text-image--${textBg}`} style={{textAlign: textAlign ?? 'left' }}>
      <div className="text-image__text" style={{alignItems: textAlign === 'center' ? 'center' : 'flex-start' }}>
        {children}
      </div>
      {img && !videoSrc &&
        <div className="text-image__image" style={imageStyle}>
          {img}
        </div>
      }
      {videoSrc &&
        <div className="text-image__image" style={imageStyle}>
          <div>
            <Video src={videoSrc} />
          </div>
        </div>
      }
      {contactForm &&
        <div className="text-image__image" style={imageStyle}>
          <div>
            <ContactForm />
          </div>
        </div>
      }
    </Section>
  )
}

export default TextImage
