import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const Video = ({ src }) => {

  return (
    <video playsInline autoPlay muted loop>
    {// need to come back and fix this loading
    }
      <source src={src} />
    </video>
  )
}

export default Video
