import React from 'react'
import resolveLink from '~utils/resolveLink'
import Button from '~components/global/button'

const InternalButton = ({ data }) => (
  <Button color={data.color} to={resolveLink(data.to)}>
    {data.buttonText}
  </Button>
)

export default InternalButton
