import React from 'react'
import { Link } from 'gatsby'
import { ButtonArrow } from '~components/global/svg'

const Ticker = ({ text, link, bg, textColor, highlightColor, children }) => {
  if(!text){
    return false
  }

  const highlight = highlightColor || '#EC873E' // orange

  let num = Math.floor(300 / text.length)
  let content = children ?? text

  if(!link){
    return(
      <div className={`ticker`} style={{backgroundColor: bg ?? '#ffffff', color: textColor ?? '#000000'}}>
        <span className="s-caps gpu">
          {[...Array(num)].map((e, i) => (
            <>
            {content}&nbsp;<ButtonArrow />&nbsp;
            </>
          ))}
        </span>
        <span className="s-caps gpu">
          {[...Array(num)].map((e, i) => (
            <>
            {content}&nbsp;<ButtonArrow />&nbsp;
            </>
          ))}
        </span>
      </div>
    )
  }
  else{
    return(
      <Link to={link} className={`ticker`} style={{backgroundColor: bg ?? '#ffffff', color: textColor ?? '#000000'}}>
        <span className="s-caps gpu">
          {[...Array(num)].map((e, i) => (
            <>
            {content}&nbsp;<ButtonArrow />&nbsp;
            </>
          ))}
        </span>
        <span className="s-caps gpu">
          {[...Array(num)].map((e, i) => (
            <>
            {content}&nbsp;<ButtonArrow />&nbsp;
            </>
          ))}
        </span>
      </Link>
    )
  }

}

export default Ticker
